import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuarioLogueado: false,
    datosUsuarioLogueado: null,
    activeMenu: false,
    newUpdate: false,
  },
  mutations: {
    SET_REFRESH(state, valueBoolean) {
      state.newUpdate = valueBoolean;
    },
    SET_USUARIO_LOGUEADO(state, value) {
      state.usuarioLogueado = value;
    },
    SET_DATOS_USUARIO_LOGUEADO(state, data) {
      state.datosUsuarioLogueado = data;
    },
    SET_ACTIVE_MENU(state, valueBoolean) {
      state.activeMenu = valueBoolean;
    },
  },
  actions: {},
  modules: {},
});
