export default ({ store, next }) => {
  setTimeout(() => {
    if (store.state.datosUsuarioLogueado.tipoUsuario !== 3) {
      next({
        name: "asignaciones",
      });
      return false;
    }

    next();
  }, 5);
};
