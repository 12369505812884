<template>
 <Default>
    <div class="usuarios">
    <banner :titulo="setNombre" :descripcion="setDescripcion" v-if="datosUsuarioLogueado"></banner>

    <section class="main__content">
      <!-- <pre>
        {{ datosUsuarioLogueado }}
      </pre> -->
    </section>

  </div>
 </Default>
</template>

<script>
import { mapState } from 'vuex'
import Default from "@/layouts/default.vue";

import Banner from "@/components/global/Banner";

export default {
  data() {
    return {
    };
  },
  components: {
    Banner, Default
  },
  computed: {
    ...mapState(['datosUsuarioLogueado']),
    setNombre() {
      let value = 'Admin'

      if(this.datosUsuarioLogueado) {
        value = `${this.datosUsuarioLogueado.nombres} ${this.datosUsuarioLogueado.apellidos}`
      }

      return value
    },
    setDescripcion() {
      let value = 'Admin'

      if(this.datosUsuarioLogueado) {
        if(this.datosUsuarioLogueado.tipoUsuario === 1) {
          value = 'Administrador principal'
        } else if(this.datosUsuarioLogueado.tipoUsuario === 2) {
          value = 'Supervisor'
        } else if(this.datosUsuarioLogueado.tipoUsuario === 3) {
          value = 'Contratista'
        }
      }

      return value
    }
  }
};
</script>

<style lang="scss">
</style>