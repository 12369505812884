// Docs https://apollo.vuejs.org/guide/

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
// import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link';

Vue.use(VueApollo)

// HTTP connection to the API
const httpLink = createUploadLink({
    // You should use an absolute URL here
    uri: 'https://api.rayroasociadossac.com/public/graphql'
})

const authMiddleware = new ApolloLink((operation, forward) => {

    // let token = $cookies.get('apiquimi') || null
    let token = Vue.$cookies.get('apiquimi_token')

    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      }
    });
    
    return forward(operation);
})

// Cache implementation
const cache = new InMemoryCache({
    addTypename: false
})

// Create the apollo client
const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache
})

export default new VueApollo({
    defaultClient: apolloClient
})