<template>
  <main class="main">
    <!-- <aside v-if="$route.name !== 'login'"> -->
    <sidebar></sidebar>
    <!-- </aside> -->

    <section class="w-100">
      <nav-app></nav-app>
      <router-view
        class="main-router-content"
        :class="isLogin ? 'remove-margin' : ''"
      ></router-view>
          <div class="footer" v-if="datosUsuarioLogueado">
      <a href="http://www.ando.pe/" target="_blank"
        >Desarrollado por Ando Marketing</a
      >
    </div>
    </section>


  </main>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "@/components/global/Sidebar";
import NavApp from "@/components/global/NavApp";

export default {
  mounted() {
    // this.getItems()
    let userAuth = !!this.$cookies.get("apiquimi_token");
    let data = this.$cookies.get("apiquimi_user");

    this.$store.commit("SET_USUARIO_LOGUEADO", userAuth);
    this.$store.commit("SET_DATOS_USUARIO_LOGUEADO", data);
  },
  components: {
    Sidebar,
    NavApp,
  },
  computed: {
     ...mapState(["datosUsuarioLogueado"]),
    isLogin() {
      return this.$route.name === "login";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  /* position: fixed;
  z-index: 3; */
  margin-top: 50px;
  left: 0;
  bottom: 0;
  height: 50px;
  text-align: center;
  background: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    font-size: 1rem;
    color: #fff;
  }
}
</style>

<style lang="scss">
.main {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;

  &-router-content {
    margin-top: 70px;
  }

  aside {
    background-color: #2a2a2e;
  }

  &__content {
    width: 100%;

    margin-top: -3rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.remove-margin {
  margin-top: 0px;
}

@media (min-width: 768px) {
  .main {
    &__content {
      width: 90%;
    }
  }
}
</style>
