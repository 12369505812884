<template>
  <div>
    <b-modal
      v-model="modalShow"
      centered
      hide-footer
      title="Nuevo contenido esta disponible"
    >
      <p class="my-4">Haga clic en el botón para actualizar la información</p>
      <hr />
      <b-button @click="refresh" class="float-right" variant="outline-primary"
        >Actualizar</b-button
      >
    </b-modal>

    <slot />
  </div>
</template>

<script>
export default {
  computed: {
      modalShow(){
        return this.$store.state.newUpdate
      },
  },
  methods: {
    refresh() {
      this.$store.commit("SET_REFRESH", false)
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.refresh-modal {
  position: fixed;
  z-index: 20;
  width: 500px;
  height: 500px;
  background: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
