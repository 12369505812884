/* eslint-disable no-console */

import { register } from "register-service-worker";

// SETEA LO BASICO
if (process.env.NODE_ENV === "production") {
  console.log(`${JSON.stringify(process.env)}`);
  // register(`${process.env.BASE_URL}registerServiceWorker.js`, {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(e) {
      console.log("ESTAMOS READY", e);
      console.log(
        "App is being served pls let me sleep.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(e) {
      console.log("HA SIDO REGISTRADO", e);
      console.log("Service worker has been registered.");
    },
    cached(e) {
      console.log("CACHE ACTIVADO HA SIDO CACHEADO", e);
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    // updated() {
    //   console.log("New content is available; please refresh.");
    //   store.commit("SET_REFRESH", true);
    //   // vnode.context.$store.commit("SET_REFRESH", true);
    // },
    updated() {
      console.log("New content is available; Refresh...");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
