<template>
  <aside v-if="datosUsuarioLogueado">
    <b-sidebar
      v-model="activeMenu"
      backdrop
      shadow
      variant="dark"
      bg-variant="dark"
      text-variant="light"
    >
      <div class="pt-2 pb-3 text-center">
        <img class="main-logo" :src="logo" alt="" />
      </div>
      <div class="py-2">
        <ul
          class="navbar-menu-side"
          v-if="datosUsuarioLogueado.tipoUsuario === 1"
        >
          <li
            class="navbar-menu-side__father"
            v-for="(item, index) of menuAdmin"
            :key="index"
          >
            <p @click="collapseTable(item)">
              <i :class="item.icon" class="mr-3"></i> {{ item.title }}
            </p>
            <b-collapse v-if="item.child" v-model="item.collapsed" class="mt-2">
              <ul>
                <router-link
                  :to="child.href"
                  v-for="(child, ix) of item.child"
                  :key="'A' + ix"
                >
                  <li class="navbar-menu-side__child">
                    {{ child.title }}
                  </li>
                </router-link>
              </ul>
            </b-collapse>
          </li>
        </ul>
        <ul
          class="navbar-menu-side"
          v-if="datosUsuarioLogueado.tipoUsuario === 2"
        >
          <li
            class="navbar-menu-side__father"
            v-for="(item, index) of menuSupervisor"
            :key="index"
          >
            <p @click="collapseTable(item)">
              <i :class="item.icon" class="mr-3"></i> {{ item.title }}
            </p>
            <b-collapse v-if="item.child" v-model="item.collapsed" class="mt-2">
              <ul>
                <router-link
                  :to="child.href"
                  v-for="(child, ix) of item.child"
                  :key="'A' + ix"
                >
                  <li class="navbar-menu-side__child">
                    {{ child.title }}
                  </li>
                </router-link>
              </ul>
            </b-collapse>
          </li>
        </ul>
        <ul
          class="navbar-menu-side"
          v-if="datosUsuarioLogueado.tipoUsuario === 3"
        >
          <li
            class="navbar-menu-side__father"
            v-for="(item, index) of menuSupervisorContratista"
            :key="index"
          >
            <p @click="collapseTable(item)">
              <i :class="item.icon" class="mr-3"></i> {{ item.title }}
            </p>
            <b-collapse v-if="item.child" v-model="item.collapsed" class="mt-2">
              <ul>
                <router-link
                  :to="child.href"
                  v-for="(child, ix) of item.child"
                  :key="'A' + ix"
                >
                  <li class="navbar-menu-side__child">
                    {{ child.title }}
                  </li>
                </router-link>
              </ul>
            </b-collapse>
          </li>
        </ul>
        <ul
          class="navbar-menu-side"
          v-if="datosUsuarioLogueado.tipoUsuario === 4"
        >
          <li
            class="navbar-menu-side__father"
            v-for="(item, index) of menuFinanzas"
            :key="index"
          >
            <p @click="collapseTable(item)">
              <i :class="item.icon" class="mr-3"></i> {{ item.title }}
            </p>
            <b-collapse v-if="item.child" v-model="item.collapsed" class="mt-2">
              <ul>
                <router-link
                  :to="child.href"
                  v-for="(child, ix) of item.child"
                  :key="'B' + ix"
                >
                  <li class="navbar-menu-side__child">
                    {{ child.title }}
                  </li>
                </router-link>
              </ul>
            </b-collapse>
          </li>
        </ul>
      </div>
    </b-sidebar>
  </aside>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      logo: require("@/assets/imagenes/logorayroblanco.svg"),
      menuAdmin: [
        // {
        //   header: true,
        //   title: "Panel de administración",
        //   hiddenOnCollapse: true,
        // },
  
        {
          href: "/usuarios",
          title: "Usuarios",
          icon: "fa fa-chart-area",
          collapsed: false,

          child: [
            {
              href: "/usuarios",
              title: "Listar",
            },
            {
              href: "/usuarios/crear",
              title: "Registrar",
            },
          ],
        },
        {
          href: "/asignaciones",
          title: "Asignaciones",
          icon: "fas fa-chart-area",
          collapsed: false,

          child: [
            {
              href: "/asignaciones",
              title: "Listar",
            },
              {
              href: "/asignaciones/adelantos",
              title: "Adelantos",
            },
            {
              href: "/asignaciones/crear",
              title: "Registrar",
            },
          ],
        },
        {
          href: "/locales",
          title: "Locales",
          icon: "fas fa-place-of-worship",
          collapsed: false,

          child: [
            {
              href: "/locales",
              title: "Listar",
            },
            {
              href: "/locales/crear",
              title: "Registrar",
            },
          ],
        },
        {
          href: "/reportes",
          title: "Reportes",
          icon: "fas fa-table",
          collapsed: false,
        },
      ],
      menuSupervisor: [
        // {
        //   header: true,
        //   title: "Panel de administración",
        //   hiddenOnCollapse: true,
        // },
     
        {
          href: "/asignaciones",
          title: "Asignaciones",
          icon: "fa fa-chart-area",
          collapsed: false,

          child: [
            {
              href: "/asignaciones",
              title: "Listar",
            },
          ],
        },
      ],
      menuSupervisorContratista: [
        // {
        //   header: true,
        //   title: "Panel de administración",
        //   hiddenOnCollapse: true,
        // },
    
        {
          href: "/asignaciones",
          title: "Asignaciones",
          icon: "fa fa-chart-area",
          collapsed: false,

          child: [
            {
              href: "/asignaciones",
              title: "Listar",
            },
            {
              href: "/asignaciones/adelantos",
              title: "Adelantos",
            },
          ],
        },
      ],
      menuFinanzas: [
        // {
        //   header: true,
        //   title: "Panel de administración",
        //   hiddenOnCollapse: true,
        // },
      

        {
          href: "/asignaciones",
          title: "Asignaciones",
          icon: "fas fa-chart-area",
          collapsed: false,

          child: [
            {
              href: "/asignaciones",
              title: "Listar",
            },
                 {
              href: "/asignaciones/adelantos",
              title: "Adelantos",
            },
          ],
        },

        {
          href: "/reportes",
          title: "Reportes",
          icon: "fas fa-table",
          collapsed: false,
        },
      ],
    };
  },
  methods: {
    collapseTable(item) {
      if (item.child) {
        item.collapsed = !item.collapsed;
      } else {
        this.$router.push(item.href);
      }
    },
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    activeMenu: {
      get() {
        return this.$store.state.activeMenu;
      },
      set(val) {
        this.$store.commit("SET_ACTIVE_MENU", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-logo {
  width: 90%;
  height: 60px;
}
a {
  color: #fff;
  text-decoration: none;
  &:hover {
    color: rgb(216, 208, 208);
    text-decoration: none;
  }
}
.navbar-menu-side {
  list-style: none;
  padding-inline-start: 0px;
  &__father {
    border-top: 2px solid #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      background: rgb(51, 38, 38);
    }
    p {
      padding: 20px;
    }
  }

  ul {
    list-style: none;
    padding-inline-start: 0px;
  }

  &__child {
    background: rgb(35, 39, 48);
    padding: 15px 30px;
    text-align: left;
    border-top: 1px solid rgb(71, 71, 71);
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
}
.sidebar-logo {
  max-width: 150px;
}
</style>
