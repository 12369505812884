<template>
  <nav
    bg-variant="dark"
    class="main-nav d-flex justify-content-between align-items-center py-2 px-3 px-md-4"
    v-if="usuarioLogueado && datosUsuarioLogueado"
  >
    <b-btn variant="info" @click="activateSidebar">
      <i class="fas fa-bars"></i>
    </b-btn>

    <div>
      <b-dropdown
        right
        class="m-md-2"
        toggle-class="text-decoration-none"
        no-caret
        size="lg"
        variant="link"
      >
        <template #button-content>
          <i class="fas fa-bell"></i>
        </template>
        <b-dropdown-item>Ver notificaciones</b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        right
        class="m-md-2"
        toggle-class="text-decoration-none"
        no-caret
        size="sm"
        variant="light"
      >
        <template #button-content>
          <img
            :src="setImagen"
            :alt="datosUsuarioLogueado.nombres"
            class="user-avatar"
          />
          <span class="user-info">
            {{ datosUsuarioLogueado.nombres }}
            {{ datosUsuarioLogueado.apellidos }}
          </span>
        </template>
        <b-dropdown-item>
          <a href="" @click.prevent="logout()">{{
            loading ? "Cerrando sesión..." : "Cerrar Sesión"
          }}</a>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";

// import Dropdown from "@/components/global/Dropdown";

export default {
  data() {
    return {
      loading: false,
      imagenDefault: require("@/assets/imagenes/user.png"),
    };
  },
  // components: {
  //   Dropdown,
  // },
  methods: {
    activateSidebar() {
      this.$store.commit("SET_ACTIVE_MENU", true);
    },

    logout() {
      this.loading = true;

      this.$store.commit("SET_ACTIVE_MENU", false);

      setTimeout(() => {
        this.$cookies.remove("apiquimi_token");
        this.$cookies.remove("apiquimi_user");

        document.cookie =
          "apiquimi_token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie =
          "apiquimi_user" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";

        this.$store.commit("SET_USUARIO_LOGUEADO", false);
        this.$store.commit("SET_DATOS_USUARIO_LOGUEADO", null);

        this.loading = false;

        this.$router.push("/login");
      }, 50);
    },
  },
  computed: {
    ...mapState(["usuarioLogueado", "datosUsuarioLogueado"]),
    setImagen() {
      let value = this.imagenDefault;

      if (this.datosUsuarioLogueado && this.datosUsuarioLogueado.foto) {
        value = this.datosUsuarioLogueado.foto;
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-nav {
  position: fixed;
  z-index:10;
  width: 100%;
  height: 70px;
  background: var(--dark);
  color: var(--light);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.34);
}

.user-info {
  font-weight: 100;
  font-size: 12px;
}

.popup-user {
  z-index: 5;
}

.user-avatar {
  width: 30px;
  height: 30px;

  border-radius: 50%;
}

@media (min-width: 768px) {
  .user-info {
    font-size: 16px;
  }
}
</style>
