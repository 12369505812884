import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import onlyAdmin from "../middlewares/onlyAdmin";
import onlySupervisor from "../middlewares/onlySupervisor";
import onlyContratist from "../middlewares/onlyContratist";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // redirect: "/asignaciones",
    name: "Home",
    component: () => import("../views/asignaciones/index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login"),
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: () => import("../views/usuarios/index"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/usuarios/crear",
    name: "usuarios-crear",
    component: () => import("../views/usuarios/editar"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/usuarios/editar/:id",
    name: "usuarios-editar",
    component: () => import("../views/usuarios/editar"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/locales",
    name: "locales",
    component: () => import("../views/locales/index"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/locales/crear",
    name: "locales-crear",
    component: () => import("../views/locales/crear"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/locales/editar/:localId",
    name: "locales-editar",
    component: () => import("../views/locales/editar"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/asignaciones",
    name: "asignaciones",
    component: () => import("../views/asignaciones/index"),
  },
  {
    path: "/asignaciones/adelantos",
    name: "asignaciones-adelantos",
    component: () => import("../views/asignaciones/adelantos"),
  },
  {
    path: "/asignaciones/crear",
    name: "asignaciones-crear",
    component: () => import("../views/asignaciones/crear"),
    meta: { middleware: [onlyAdmin] },

    // meta: {
    //   middleware: onlyAdmin,
    // },
  },
  {
    path: "/asignaciones/general/:id",
    name: "asignacion-detalles-id",
    component: () => import("../views/asignaciones/detalle-general"),
  },
  // {
  //   path: '/asignaciones/:id',
  //   name: 'asignaciones-id',
  //   component: () => import('../views/asignaciones/detalles')
  // },
  {
    path: "/reportes",
    name: "reportes",
    component: () => import("../views/reportes/index"),
    // meta: {
    //   middleware: [onlyAdmin],
    // },
  },

  // PRUEBA
  {
    path: "/asignaciones/detalles/1/:id",
    name: "asignacion-detalle-primero",
    component: () =>
      import("../views/asignaciones/vista-detalles/primeraVista"),
  },
  {
    path: "/asignaciones/detalles/2/:id",
    name: "asignacion-detalle-segundo",
    component: () =>
      import("../views/asignaciones/vista-detalles/segundaVista"),
    meta: {
      middleware: [onlySupervisor],
    },
  },
  {
    path: "/asignaciones/detalles/3/:id",
    name: "asignacion-detalle-tercero",
    component: () =>
      import("../views/asignaciones/vista-detalles/terceraVista"),
    meta: {
      middleware: [onlySupervisor],
    },
  },
  {
    path: "/asignaciones/detalles/4/:id",
    name: "asignacion-detalle-cuarto",
    component: () => import("../views/asignaciones/vista-detalles/cuartaVista"),
    meta: {
      middleware: [onlyContratist],
    },
  },
  {
    path: "/asignaciones/detalles/5/:id",
    name: "asignacion-detalle-quinto",
    component: () => import("../views/asignaciones/vista-detalles/quintaVista"),
    meta: {
      middleware: [onlyAdmin],
    },
  },
  {
    path: "/asignaciones/detalles/7/:id",
    name: "asignacion-detalle-septimo",
    component: () =>
      import("../views/asignaciones/vista-detalles/septimaVista"),
    meta: {
      middleware: [onlyContratist],
    },
  },
  {
    path: "/asignaciones/detalles/8/:id",
    name: "asignacion-detalle-octavo",
    component: () => import("../views/asignaciones/vista-detalles/octavaVista"),
    meta: {
      middleware: [onlySupervisor],
    },
  },
  {
    path: "/asignaciones/detalles/9/:id",
    name: "asignacion-detalle-noveno",
    component: () => import("../views/asignaciones/vista-detalles/novenaVista"),
    meta: {
      middleware: [onlySupervisor],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);

  let userAuth = !!Vue.$cookies.get("apiquimi_token");

  if (authRequired && !userAuth) {
    return next("/login");
  } else if (!authRequired && userAuth) {
    return next("/");
  } else {
    return next();
  }
});

export default router;
