import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import middleware from "@grafikri/vue-middleware"
import './registerServiceWorker'



// vue-apollo
// Apollo
import apolloProvider from './plugins/vue-apollo'

/* import VueApollo from 'vue-apollo'
Vue.use(VueApollo)

import ApolloClient from 'apollo-boost'
import { createUploadLink } from 'apollo-upload-client'

// HTTP connection to the API
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: 'https://apikimi.softaki.com/public/graphql'
})

const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'https://apikimi.softaki.com/public/graphql'
  link: httpLink
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
}) */

// MIDDLEWARES
router.beforeEach(middleware({store}))

// VUE SELECT
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

// VUELIDATE
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//global registration
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)


// bootstrap-vue
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// vue-sidebar-menu
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

// Custom styles
import './assets/scss/app.scss'

// vue-toast-notification
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';

// vue-cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.use(VueToast);

Vue.use(VueSidebarMenu)

// vue-moment
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
    moment
})

// vue-dropzone
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

Vue.component('vue-dropzone', VueDropzone)

// vue2-smooth-scroll https://www.npmjs.com/package/vue2-smooth-scroll
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
