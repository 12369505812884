<template>
  <section class="banner d-flex flex-column justify-content-center">
    <div class="container">
      <img v-if="fondo" class="banner__fondo" :src="fondo" alt="" />

      <div class="row justify-content-center">
        <div class="col-md-11">
          <h1 class="banner__title text-white font-weight-bold">
            {{ titulo }}
          </h1>

          <p class="banner__d">
            {{ descripcion }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    titulo: String,
    descripcion: String,
    fondo: String,
  },
};
</script>

<style lang="scss">
.banner {
  background: linear-gradient(135deg, #0061f2 0%, rgba(105, 0, 199, 0.8) 100%);
  position: relative;
  z-index: -1;
  overflow: hidden;

  height: 25vh;

  @media (min-width: 768px) {
    height: 35vh;
  }

  &__fondo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    opacity: .6;
  }

  &__title {
    font-size: 1.1em;

    @media (min-width: 768px) {
      font-size: 1.5em;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__d {
    font-weight: 400;

    color: rgba(white, 0.7);
  }
}
</style>
